.main-content-container {
  background: repeating-linear-gradient(#DC878A, #DC878A 635px, white 0%, white 100%);
  padding-top: 4vw;
  padding-bottom: 3vw;
}

.mobile-main-content-container {
  background: repeating-linear-gradient(#DC878A, #DC878A 9%, white 0%, white 100%);
}

.vision-statement-text {
  color: white !important;
  padding-top: 12vw;
}

.mobile-vision-statement-text {
  padding-bottom: 4vw;
  padding-top: 10vw;
  color: white !important;
}

.vision-statement-divider {
  border-top-width: 0px;
  width: 60vw;
  padding-top: 4vw;
  border-right-width: 0px;
  border-left-width: 0px;
}

.mobile-vision-statement-divider {
  border-top-width: 0px;
  width: 60vw;
  padding-top: 4vw;
  border-right-width: 0px;
  margin-bottom: 10vw;
  border-left-width: 0px;
}

.blurb-text-container {
  align-self: center;
}

.blurb-text {
  padding-bottom: 6vw;
}

.mobile-blurb-text {
  padding-bottom: 10vw !important;
  padding-top: 2vw;
}

.member-picture {
  height: 200px;
  padding-bottom: 1vw;
}

.mobile-member-picture {
  /* background-color: #C4C4C4;
  height: 60vw; */
  height: 200px;
  padding-bottom: 1vw;
}

.mobile-member-picture:hover {
  cursor: pointer;
}

.member-container {
  max-width: 16% !important;
  min-width: 196px !important;
}

/* .member-container:hover {
  cursor: pointer;
} */

/* .member-container:hover .member-picture{
  transform: scale(1.04);
} */

.mobile-member-container {
  width: 50% !important;
  align-self: center;
  padding-bottom: 2vw;
}

.member-name-text {
  padding-top: 2vw;
}

.member-title-text {
  padding-bottom: 4vw;
}

.mobile-linkedin-icon{
  padding-bottom: 4vw;
}

.all-members-container {
  padding-top: 6vw;
  padding-bottom: 4vw;
}

.first-members-container {
  padding-bottom: 2vw;
}

.second-members-container {
  padding-bottom: 2vw;
}

.action-text {
  width: inherit;
}

.call-to-action-banner {
  background-color: #F3D5D6;
  padding-top: 8vw;
  padding-bottom: 8vw;
}

.call-to-action-text {
  padding-bottom: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.linkedin-icon {
  color: #0072b1;
}

.linkedin-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.hover:hover {
  cursor: pointer;
}

.member-name:hover {
  color: #0072b1;
}
