.form-item {
  width: 23vw;
}

.mobile-form-item {
  width: 85vw;
}

.comment-input {
  width: 30vw;
}

.message-text{
  padding-bottom: 0.5vw;
}

.message-text :focus-visible {
  border-color: red !important;
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}

.submit-button-container {
  align-self: center;
  padding-top: 2vw;
}

.mobile-submit-button-container {
  align-self: center;
  padding-top: 10vw !important;
  padding-right: 10vw !important;
}

.email-alert {
  margin-top: 2vw;
  margin-right: 2vw;
  margin-left: 2vw;
}

.mobile-captcha-error-text {
  color:red !important;
  font-size: 0.75rem !important;
  padding-left: 6vw;
}

.captcha-error-text {
  color:red !important;
  font-size: 0.75rem !important;
}

.captcha-error-text-container {
  align-self: center;
}

.captcha-container {
  align-self: center;
  padding-top: 2vw;
}