

#blog-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#blog-header #banner {
  background-color: #94B9E1;
  height: 399px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#divider {
  border-top-width: 0px;
  width: 40vw;
  margin-bottom: 20px;
}

#image {
  height: 100%;
  position: relative;;
}

.card-media {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;               
}

.no-image-yet {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.white-text {
  padding: 15px;
}

#post-card {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  max-height: 425px;
  margin: -100px 35px 30px;
  position: relative;
  z-index: 2;
  text-align: left;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

#post-card:hover {
  transform: scale(1.015); 
  cursor: pointer; 
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.225); 
}

.featured-post .date  {
  margin-bottom: 55px;
}

.featured-post .content {
  margin-bottom: 70px; 
}

.featured-post .title {
  margin-bottom: 30px; 
}

.read-more {
  display: inline-block;
  text-decoration: none;
  color: #007bff;
}

.post-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.post-image {
  width:37%;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 10px;
}

#blog-row {
  display: flex;
  flex-direction: row;
  padding-left: 150px;
  padding-top: 85px;
  padding-right: 140px;
  padding-bottom: 100px;
  /* bottom: 50px; */
}

.card-image {
  position: relative;
  padding: 35%;
}

#row-card {
  display: flex; 
  flex-direction: column; 
  height: 100%;
  width: 98%;
  border-radius: 10px;
  transform: scale(1);
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); 
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

#row-card:hover{
  transform: scale(1.015); 
  cursor: pointer; 
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.225); 
}

#card-content-title {
  margin-bottom: 20px;
}

#row-card-read-more {
  position: absolute;
  bottom: 20px;
  left: 15px;
}

@media (max-width: 1600px){

  #post-card {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    max-height: 350px;
    margin: -80px 35px 30px;
    position: relative;
    z-index: 2;
    text-align: left;
    transform: scale(1);
    transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  }

  .featured-post .date  {
    margin-bottom: 35px;
  }
  
  .featured-post .content {
    margin-bottom: 30px; 
  }
  
  .featured-post .title {
    margin-bottom: 30px; 
  }

  #blog-row {
    display: flex;
    flex-direction: row;
    padding-left: 80px;
    padding-top: 40px;
    padding-right: 60px;
    padding-bottom: 100px;
    /* bottom: 50px; */
  }

  #row-card-read-more {
    position: absolute;
    bottom: 15px;
    left: 15px;
  }

  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
     margin-top:5px;  /* to add margin between cards */
  }
}

@media (max-width: 768px) {

  #blog-header #banner {
    height: 220px;
  }

  #post-card{
    padding-left: 12px;
    max-height: 900px;
    max-width: auto;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px;
  }

  #blog-row {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-top: 10px;
    padding-left: 28px; 
    padding-right: 13px;   
  }

  #row-card {
    height: 525px;
  }

  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    margin-top:15px;  /* to add margin between cards */
 }

  .post-image {
    order: -1;
    margin-bottom: 15px;
  }

  .post-content {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .post-image {
    width: 100%; 
  }

  #divider {
    width: 350px;
    margin-bottom: 90px;
  }
}







