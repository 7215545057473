.footer-header-logo {
  max-width: 100%;
}

/* .mobile-footer-header-logo {
  max-width: 100%;
  padding-left: 1vw;
} */

.mobile-footer-header-logo > img {
  max-width: 100%;
  /* padding-left: 1vw; */
}

.mobile-footer-hipaa-logo{
  display: flex;
  max-width: 30vw;
}

.mobile-footer-hipaa-logo > img {
  max-width: 100%;
}

.hipaa-footer-logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.hipaa-footer-logo-container img {
  width: 8vw;
}

.privacy-footer-logo-container .hoverable-text {
  font-size: 13px !important;
}

.mobile-privacy-footer-logo-container {
  width: fit-content;
  padding-left: 16vw;
}

.mobile-privacy-footer-logo-container .hoverable-text {
  font-size: 13px !important;
}

.mobile-logo-policy-container {
  width: 50vw !important;
}

.footer-logo-container {
  display: flex;
  justify-content: flex-end;
}

.footer-contact-text-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 2vw;
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.footer-menu-text {
  padding-left:2vw;
}

.footer-menu-text:hover {
  color: #D23383;
  cursor: pointer;
}

/* .footer-contact-icons-container {
  padding-left: 3vw;
} */

.footer-container {
  padding-top: 1.5vw;
  background-color: #F8FBF9;
  padding-bottom: 1.5vw;
  position:relative;
  left:0;
  bottom:0;
  right:0;
  display: flex;
  justify-content: space-evenly;
}

.footer-socials {
  display: flex;
  flex-direction: column;
}

.mobile-footer-container {
  padding-top: 5vw;
  padding-left: 5vw;
  padding-right: 4vw;
  padding-bottom: 2vw;
}

/* .linkedin-icon {
  color: #0072b1;
} */

.clickable-icon:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.hoverable-text:hover {
  cursor: pointer;
  color: #D23383;
}