.header-logo{
    width: 156px;
    height: 46px;
    /* min-width: 50%; */
    padding-left: 2vw;
}

.header-logo:hover {
    cursor: pointer;
}

.header-container{
    background-color: white;
    padding-bottom: 1vw;
    padding-top: 1.5vw;
    align-items: center;
    justify-items: center;
}

.menu-item {
    padding-right: 3vw; 
}

.menu-item-circled {
    padding-right: 2vw;
}

.menu-item-text {
    color: #D23383;
    size: 80px;
    
}

.menu-item-text:hover {
    color: #D23383;
    cursor: pointer;
}

.drop-down-menu-content {
    padding-top: 20vw;
}

.drop-down-menu-item-text {
    padding-left: 3vw;
    padding-bottom: 1vw;
    padding-top: 1vw;
}

.drow-down-menu-item:hover {
    background-color: #E5E5F0;
}

.appBar {
    z-index: 1301 !important;
    background-color: white !important;
    color: black !important;
}

.mobile-header-logo {
    padding-top: 3vw;
    padding-left: 10%;
    max-width: 40vw;
}

.hamgurger-icon {
    padding-top: 1.5vw;
    padding-right: 5vw;
    transform: scale(1.8);
    max-width: 75%;
    color: black !important;
}