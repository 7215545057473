/* Banner */
.patient-title-and-banner-container {
  /* background: repeating-linear-gradient(#8487B5, #8487B5 69.9%, white 70.1%, white 100%); */
  background-color: #8487B5;
  height: 599px;
  position: relative;
}

.mobile-patient-title-and-banner-container {
  /* background: repeating-linear-gradient(#8487B5, #8487B5 69.9%, white 70.1%, white 100%); */
  background-color: #8487B5;
  position: relative;
}

.banner-picture {
  background-color: #8487B5;
  height: 25vw;
}

.mobile-banner-picture {
  background-color: #8487B5;
  height:40vw;
}

.patient-title-text-container {
  align-self: center;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.patient-mobile-title-text-container {
  padding-bottom: 17vw;
  padding-top: 17vw;
  padding-left: 4vw;
  padding-right: 4vw;
}

.patient-header-text {
  color: white !important;
}

.mobile-patient-header-text {
  color: white !important;
}

/* Patients Statement */
.patient-applications-title-text-div{
  display: flex;
  align-items: center;
  padding-top: 3vw;
  padding-bottom: 2vw;
}

.patient-applications-divider-border{
  border-bottom: 1px solid #353885 !important;
  flex-grow: 1 !important;
  min-width: 10vw;
}

.patient-applications-divider-content{
  padding: 0 7px 0 7px !important;
}

.patient-applications-title-text{
  padding-top: 8vw;
  padding-bottom: 8vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

/* Reasons */
.reason-title {
  padding-top: 1vw;
  padding-left: 1vw;
}

.patient-reason-text {
  padding-top: 2vw;
  padding-right: 4vw;
  padding-left: 4vw;
}

.patient-div-right {
  border-right: 0.5px solid black !important;
  height: inherit
}

.reasons-container {
  padding-left: 2vw;
  padding-right: 2vw;
  height: 16vw;
  margin-bottom: 10vw;
}

.mobile-reasons-container {
  align-items: center;
}

.mobile-reason-title {
  align-self: center;
}

.patient-mobile-reason-container {
  align-self: center;
  padding-bottom: 10vw;
}

.patient-mobile-reason-title-container {
  align-self: center;
}

.patient-reason-text {
  padding-bottom: 12vw;
}
/* 
.reason-text {
  padding-top: 2vw;
  padding-left: 1vw;
  padding-bottom: 1vw;
} */
/* 
.reason-container {
  border-style: solid;
  margin-left:1vw !important;
  margin-right:1vw !important;
}

.mobile-reason-container {
  border-style: solid;
  align-self: center;
  margin-top: 6vw !important;
  margin-bottom: 4vw !important;
}

.all-reasons-container {
  padding-top: 3vw;
  padding-bottom: 5vw;
} */

/* Call to action banner */
.patient-call-to-action-container {
  /* background: repeating-linear-gradient(#8487B5, #8487B5 69.9%, white 70.1%, white 100%); */
  background-color: #D4D5E5;
  padding-top: 6vw;
  padding-bottom: 3vw;
  align-items: center;
}

.patient-call-to-action-text {
  color: black;
  padding-left: 15vw;
  padding-right: 15vw;
}

.mobile-patient-call-to-action-text {
  color: black;
  padding-left: 8vw;
  padding-right: 8vw;
  padding-top: 10vw;
  padding-bottom: 6vw;
}