.container-div{
    display: flex;
    justify-content: center;
}

.img-div{
    height: 30vw;
    width: 30vw;
    max-height: inherit;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: #1c1c1c;
    border-radius: 1em;
}

.mobile-img-div{
    height: 85vw;
    width: 85vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: #1c1c1c;
}

.img{
    object-fit: contain;
}

.indicators{
    display: flex;
    justify-content: center;
    margin: 5px;
    padding-top: 2vw;
}

.circle-icon{
    width: 2vw;
    height: 2vw;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    border-radius: '50%';
    background-color: 'currentColor';
    color: #000000;
}