#blog-page {
    background: repeating-linear-gradient(#94B9E1, #CCDDF1, white 100%);
    position: relative;
    padding-bottom: 3vw;
  }
  
  #back-button {
    position: relative;
    left: 6vw;
    top: 86px;
  }
  
  #blog-page-header-title {
    padding-top: 2.5vw;
    padding-bottom: 4vw;
  }
  
  #blog-page-header-text {
    color: white !important;
    font-weight: 600 !important;
    padding-left: 14vw;
    padding-right: 14vw;
  }
  
  #blog-img-container {
    position: relative;
    height: 24vw;
    z-index: 1;
    overflow: hidden;
    border-radius: 15px;
    align-items: center;
  }
  
  #blog-img {
    border-radius: 15px;
    position: relative;
    width: 100%;
    height: 100%;
  }

  #blog-document-with-noImage {
    background-color: #ffffff;
    position: relative;
    width: 85%;
    border-radius: 15px;
    padding: 14vw 6vw 4vw 6vw;
  }

  #blog-document {
    background-color: #ffffff;
    
    position: relative;
    bottom: 12vw;
    width: 90%;
    
    border-radius: 15px;
    padding: 14vw 6vw 4vw 6vw;
  }
  
  #blog-document-date {
    font-weight: 600 !important;
  }
  
  #blog-document-paragraph {
    padding-top: 3vw;
  }
  
  @media (max-width: 768px) {
    #blog-page {
      background: repeating-linear-gradient(#94B9E1, #CCDDF1, white 100%);
      position: relative;
      padding-bottom: 80px;
    }
    
    #blog-page-header-title {
      padding-top: 15vw;
      padding-bottom: 12vw;
    }
    
    #blog-img-container {
      position: relative;
      top: 0;
      /* height: 100vw; */
      width: 100%;
      height: auto;
      padding-left: 25px;
      padding-right: 25px;
      z-index: 1;
      overflow: hidden;
      border-radius: 15px;
      /* bottom: 7vw; */
      padding-bottom: 10vw;
    }
    
    #blog-img {
      border-radius: 15px;
      position: relative;
      width: 100%;
    }
    #blog-document-paragraph {
        font-size: 16px; /* Adjust font size as needed */
      }
    
    #blog-document {
      background-color: #ffffff;
      position: relative;
      top: 0;
      
      /* bottom: 40vw; */
      width: 90%;
      border-radius: 25px;
      padding: 4vw 6vw 4vw 6vw;
    }
    
    #back-button {
      display: none;
    }
    
  
  }