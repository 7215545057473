.title-and-banner-container {
  /* background: repeating-linear-gradient(#EFEFEF, #EFEFEF 69.9%, white 70.1%, white 100%); */
  background-color: #72AC7E;
  height: 599px;
  position: relative;
}

.mobile-title-and-banner-container {
  background: #72AC7E;
  padding-top: 10vw;
  padding-bottom: 10vw;
  padding-right: 2vw;
  padding-left: 2vw;
}

.mobile-title-text-container {
  padding-bottom: 7vw;
  padding-top: 4vw;
  padding-left: 4vw;
  padding-right: 4vw;
}

.title-text-container {
  align-self: center;
  padding-top: 4vw;
  padding-bottom: 2vw;
}

.white-text{
  color: white !important;
}

.button-container {
  align-self: center;
  padding-top: 2vw;
  padding-bottom: 4vw;
}

.mobile-button-container {
  align-self: center;
  padding-top: 2vw;
  padding-bottom: 10vw;
}

.demo-button{
  /* margin: 50px; */
  width: 187px !important;
  height: 62px;
}

.quote-div{
  margin-top: 6vw;
  align-items: center;
  padding-left: 9vw;
  padding-right: 9vw;
  padding-bottom: 5vw;
}

.banner-picture {
  background: white;
  height: 282px;
  object-fit: contain;
  padding-left: 3vw;
  padding-bottom: 9vw;
}

.mobile-banner-picture {
  background: white;
  height: 200px;
  width: 70vw;
  padding-top: 10vw;
  padding-bottom: 4vw;
  object-fit: contain;
}

/* .begin-quotation-mark{
  font-size: 80px !important;
  color: #353885 !important;
  height: 3vw;
} */

/* .mobile-begin-quotation-mark{
  font-size: 90px !important;
  color: #353885 !important;
  height: 3vw;
  padding-bottom: 80%;
  padding-left: 2vw;
} */

.quote{
  color: #1D1D1D !important;
  padding-left: 1vw;
  padding-right: 5vw;
  padding-top: 1vw;
}

.mobile-quote{
  color: #1D1D1D !important;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

/* .end-quotation-mark{
  font-size: 80px !important;
  color: #353885 !important;
  height: 2vw;
  width: 7vw;
} */

/* .mobile-end-quotation-mark{
  font-size: 90px !important;
  color: #353885 !important;
  height: 2vw;
  width: 5vw;
  padding-left: 6vw;
  padding-bottom: 2vw;
} */

.hyphen{
  font-size: 70px !important;
  color: #353885 !important;
  padding-right: 3vw;
  padding-bottom: 2.5vw;
}

.quotee{
  justify-self: center;
  align-self: center;
  padding-bottom: 2vw;
  padding-right: 6vw;
}

.title-text-div{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 3vw;
  padding-bottom: 5vw;
}

.mobile-title-text-div{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 12vw;
  padding-bottom: 12vw;
}

.divider-border{
  border-bottom: 1px solid #353885 !important;
  flex-grow: 1 !important;
}

.transparent-border{
  border-bottom: 0px !important;
  flex-grow: 1 !important;
}

.mobile-divider-border{
  border-bottom: 1px solid #353885 !important;
  flex-grow: 1 !important;
  min-width: 10vw;
}

.divider-content{
  padding: 0 70px 0 70px !important;
}

.mobile-divider-content{
  padding: 0 0 0 0 !important;
}

.reasons-title-grid{
  padding-top: 4vw;
}

.mobile-reasons-title-grid{
  padding-top: 6vw;
}

.reason-name-text{
  padding-top: 1vw;
  padding-bottom: 2vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

.div-right{
  border-right: 0.5px solid #000000 !important;
}

.div-both{
  border-right: 0.5px solid #000000 !important;
  border-left: 0.5px solid #000000 !important;
}

.div-left{
  border-left: 0.5px solid #000000 !important;
}

.reasons-grid{
  padding-bottom: 4vw;
}

.reason-description-text{
  padding-bottom: 2vw;
  padding-left: 5vw;
  padding-right: 5vw;
}

.mobile-reason-description-text{
  padding-bottom: 16vw;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 6vw;
}

.video-title{
  padding-top: 3vw;
}

.video-container{
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 12vw;
}

.mobile-video-container{
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 20vw;
}

.video{
  width: 50%;
  height: 26.2vw;
}

.mobile-video{
  width: 75%;
  height: 39.3vw;
}

.booking-container {
  background: rgba(114, 172, 126, 0.35);
  padding-top: 8vw;
  padding-bottom: 8vw;
}

.mobile-booking-container {
  background: rgba(114, 172, 126, 0.35);
  padding-top: 8vw;
  padding-bottom: 12vw;
}

.booking-text{
  padding-bottom: 2vw;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
}

.mobile-booking-text{
  padding-bottom: 2vw;
  padding-left: 7vw;
  padding-right: 7vw;
}
