/* Banner styles */
.header-grid{
    background: url("../../../public/home/Website_Teisha2.png");
    background-repeat: no-repeat;
    background-size: 60vw;
    background-position: top 5rem right;
}

.first-photo{
    width: 100vw;
    margin-left: -8vw;
}

.banner-image-container{
    height: 50vw;
    padding-left: 2vw;
    padding-right: 3vw;
    background-clip: content-box;
}

.banner-title-text{
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 2vw;
}

.banner-divider{
    align-self: center;
    max-width: 40vw;
    border: 0.5px solid;
}

.banner-subtitle-text{
    padding-left: 6vw;
    padding-right: 6vw;
    padding-bottom: 2vw;
}

.surgeon-patient-button-container{
    padding-bottom: 2vw;
}

.mobile-banner-image-container{
    height: 70vw;
    width: 85vw;
    padding-left: 1vw;
    padding-right: 1vw;
}

.mobile-banner-title-text{
    padding-top: 4vw;
    padding-bottom: 2vw;
}

.mobile-banner-divider{
    align-self: center;
    max-width: 70vw;
    border: 0.5px solid;
}

.mobile-banner-subtitle-text{
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vw;
    padding-bottom: 12vw;
}

/*What We Do*/
.what-we-do-title-text{
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 2vw;
}

/* Mission statement styles */
.mission-statement-text{
    padding-top: 5vw;
    padding-bottom: 1vw;
}

.mission-statement-divider{
    align-self: center;
    max-width: 50vw;
    border: 0.5px solid;
    margin-bottom: 4vw;
}

.mobile-mission-statement-text{
    padding-left: 12vw;
    padding-right: 12vw;
    padding-top: 5vw;
    padding-bottom: 4vw;
}

/* About us styles */
.about-us-grid{
    padding-top: 4vw;
    background-image: url(../../../public/home/background-dots/desktop/about-us-dots-transparent.png);
    background-size: 100vw;
    background-position: center ;
    background-repeat: no-repeat;
}

.about-us-image-container{
    height: 40vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    padding-left: 1.5vw;
    background-clip: content-box;
}

.about-us-title-text{
    padding-top: .5em;
    padding-left: 9vw;
    padding-right: 9vw;
}

.about-us-body-text{
    padding-top: 1em;
    padding-left: 9em;
    padding-right: 9em;
}

.about-us-button-container{
    padding-bottom: 3vw;
}

.mobile-about-us-grid{
    padding-top: 4vw;
    padding-bottom: 4vw;
}

.mobile-about-us-title-text{
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 20vw;
    padding-bottom: 10vw;
}

.mobile-about-us-image-container{
    width: 85vw;
    padding-top: 16vw;
}

.mobile-about-us-body-text{
    padding-left: 8vw;
    padding-right: 8vw;
    padding-bottom: 13vw;
    padding-top: 3vw;
}

.mission-and-what-we-do-container {
    padding-top: 9vw;
    margin-bottom: 5vw;
    background-image: url(../../../public/home/background-dots/desktop/what-we-do-dots-banner-transparent-v2.png);
    background-size: 100vw;
    background-position: top center;    
}

/* Applications styles */
.applications-title-text-div{
    display: flex;
    align-items: center;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 4vw;
    padding-bottom:6vw;
}

.applications-title-text{
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}

.applications-divider-border{
    border-bottom: 1px solid #353885 !important;
    flex-grow: 1 !important;
}

.applications-divider-content{
    padding: 0 10px 0 10px !important;
}

.applications-divider{
    align-self: center;
    max-width: 30vw;
    border: 0.5px solid;
    margin-bottom: 4vw;
}

.div-right{
    border-right: 0.5px solid #353885 !important;
}

.div-both{
    border-right: 0.5px solid #353885 !important;
    border-left: 0.5px solid #353885 !important;
}

.div-left{
    border-left: 0.5px solid #353885 !important;
}

.applications-product-name-text{
    padding-top: 1vw;
    padding-bottom: 5vw;
}

.applications-product-description-text{
    padding-bottom: 2vw;
    padding-left: 3vw;
    padding-right: 3vw;
}

.second-photo {
    position: relative;
    width: 45vw;
    margin-left: 3vw;
    border-radius: 21px;
}

.second-photo-mobile {
    width: 86vw;
}

.product-image-container{
    background-color: 'inherit';
    height: 30vw;
    width: 30vw;
    border-radius: 50px;
}

.carousel-container{
    padding-top: 5vw;
}

.mobile-applications-title-text-div{
    display: flex;
    align-items: center;
    padding-top: 3vw;
    padding-bottom: 2vw;
}

.mobile-applications-title-text{
    padding-top: 8vw;
    padding-bottom: 8vw;
}

.mobile-applications-divider-border{
    border-bottom: 1px solid #353885 !important;
    flex-grow: 1 !important;
    min-width: 10vw;
}

.mobile-applications-divider-content{
    padding: 0 10px 0 10px !important;
}

.mobile-applications-divider{
    align-self: center;
    max-width: 90vw; 
    border: 0.5px solid;
    margin-bottom: 4vw;
}

.mobile-applications-product-name-text{
    padding-top: 10vw;
    padding-bottom: 10vw;
}

.mobile-applications-product-description-text{
    padding-bottom: 8vw;
    padding-left: 8vw;
    padding-right: 8vw;
}

.mobile-product-image-container{
    background-color: 'inherit';
    height: 105vw;
    width: 85vw;
}

/* Provoke booking styles */
.question-to-provoke-text{
    padding-bottom: 5vw;
    padding-left: 10vw;
    padding-right: 10vw;
}

.question-to-provoke-container{
    margin-top: 10vw;
    padding-top: 7vw;
    padding-bottom: 7vw;
}

.mobile-question-to-provoke-text{
    padding-top: 13vw;
    padding-bottom: 10vw;
    padding-left: 8vw;
    padding-right: 8vw;
}

/* Other */
.spacing{
    height: 7vw;
}

.larger-spacing{
    height: 12vw;
}

.mobile-container{
    flex-direction: unset !important;
}

.orange-background-dots {
    max-width: 17vw;
    height: inherit;
}

.green-background-dots {
    max-width: 20vw;
    justify-content: flex-end;
    align-self: flex-end;
}

.bottom-green-dots-container {
    display: flex;
}

.blue-green-dots-banner {
    padding-top: 5vw;
    max-width: 100vw;
}

.mobile-orange-left-dots {
    max-width: 100vw;;
}

.mobile-green-right-dots {
    max-width: 100vw;
    margin-bottom: -2vw;
    padding-top: 20vw;
}
