.contact-us-page-container {
  padding-top: 4vw;
  padding-bottom: 6vw;
}

.mobile-content-container {
  padding-left: 6vw;
  padding-top: 10vw;
  padding-bottom: 10vw;
}

.mobile-contact-info {
  padding-top: 4vw;
}

.mobile-bottom-contact-info-item {
  padding-bottom: 10vw !important;
}

.mobile-icons-container {
  padding-top:8vw;
  padding-right: 10vw !important;
}